<template>
    <b-card
            no-body
            class="p-1"
    >
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <div class="demo-spacing-0 m-1"
                 dir="ltr"
            >
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    فیلتر
                    <feather-icon icon="FilterIcon"/>
                </b-button>
            </div>

            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"
            />

            <history-details :is-active.sync="isDetails" :order="detail"/>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        @sort-changed="sort($event)"
                        no-local-sort
                        style="white-space: nowrap; min-height : 235px"
                >

                    <template #cell(createdAtDateTime)="data">
                        <span dir="ltr">
                            {{$G2J(data.item.createdAtDateTime)}}
                        </span>
                    </template>

                    <template #cell(amount)="data">
                        <span dir="ltr">
                            {{$toLocal(data.item.amount,$decimal[data.item.relatedCoin]) || 0}}
                        </span>
                    </template>

                    <template #cell(relatedCoin)="data">
                        <b-avatar
                                size="25"
                                class="mr-50"
                                :src="`/coins/${data.item.relatedCoin}.png`"
                                :variant="`light-info`"
                        />
                        {{$coins[data.item.relatedCoin].persianName}}
                    </template>

                    <template #cell(transactionStatus)="data">
                        <b-badge
                                pill
                                :variant="'light-'+statusVariant(data.item.transactionStatus)"
                                class="text-capitalize"
                        >
                            {{statusLabel(data.item.transactionStatus)}}
                        </b-badge>
                    </template>

                    <template #cell(withdrawalRequestStatus)="data">
                        <b-badge
                                pill
                                :variant="'light-'+statusVariant(data.item.withdrawalRequestStatus)"
                                class="text-capitalize"
                        >
                            {{statusLabel(data.item.withdrawalRequestStatus)}}
                        </b-badge>
                    </template>

                    <template #cell(details)="{item}">
                        <feather-icon
                                icon="FileTextIcon"
                                size="20"
                                class="cursor-pointer"
                                v-tooltip="'مشاهده جزییات معامله'"
                                @click="detail=item;isDetails=true;"
                        />
                    </template>

                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>
                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BPagination,
        BCard,
        BButton,
        BTable,
        // BDropdown,
        // BDropdownItem,
        BBadge,
        BAvatar,
        BOverlay,
    } from 'bootstrap-vue'
    import SearchAndFilter from "@/layouts/components/SearchAndFilter";
    import vSelect from 'vue-select';
    import HistoryDetails from "@/views/Accounting/Transaction/historyDetails";

    export default {
        name: 'withdrawHistory',
        components: {
            HistoryDetails,
            SearchAndFilter,
            BPagination,
            BCard,
            BButton,
            BTable,
            // BDropdown,
            // BDropdownItem,
            BBadge,
            BAvatar,
            vSelect,
            BOverlay,
        },
        data: () => ({
            test: 0,
            pageLength: 3,
            dir: false,
            searchTerm: '',
            currentPage: 1,
            perPage: 10,
            rows: 1,
            sortBy: '',
            isSortDirDesc: '',
            userData: [],
            isActive: false,
            isDetails: false,
            perPageOptions: [5, 10, 20, 50, 100],
            items: [],
            columns: [
                {
                    label: 'نام کاربر',
                    key: 'customer',
                    sortable: false,
                    searchType: 'text'
                },
                {
                    label: 'مقدار تراکنش',
                    key: 'amount',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'واحد تراکنش',
                    key: 'relatedCoin',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: [
                        {label: 'تومان', value: 'TOMAN'},
                        {label: 'بیتکوین', value: 'BITCOIN'},
                        {label: 'اتریوم', value: 'ETHEREUM'},
                        {label: 'بیتکوین کش', value: 'BITCOIN_CASH'},
                    ]
                },
                {
                    label: 'تاریخ بررسی',
                    key: 'createdAtDateTime',
                    sortable: true,
                    searchType: 'date'
                },
                {
                    label: 'وضعیت تراکنش',
                    key: 'transactionStatus',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: [
                        {label: 'تایید شده', value: 'CONFIRMED'},
                        {label: 'تایید نشده', value: 'UNCONFIRMED'},
                        {label: 'انجام نشده', value: 'RAISED_BY_USER'},
                    ]
                },
                {
                    label: 'وضعیت',
                    key: 'withdrawalRequestStatus',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: [
                        {label: 'لغو شده توسط مدیر', value: 'CANCELLED_BY_ADMIN'},
                        // {label: 'لغو شده توسط کاربر', value: 'CANCELLED_BY_USER'},
                        {label: 'انجام شده', value: 'ACCEPTED'},
                    ]
                },
                {
                    label: 'جزییات',
                    key: 'details'
                },
            ],
            detail:{},
        }),
        computed: {
            statusVariant() {
                const a = {
                    CANCELLED_BY_ADMIN : 'danger',
                    ACCEPTED : 'success',
                    RAISED_BY_USER : 'info',
                    UNCONFIRMED : 'warning',
                    CONFIRMED : 'success',
                }
                return e => a[e];
            },
            statusLabel() {
                const a = {
                    CANCELLED_BY_ADMIN : 'لغو شده توسط مدیر',
                    ACCEPTED : 'انجام شده',
                    RAISED_BY_USER : 'انجام نشده',
                    UNCONFIRMED : 'تایید نشده',
                    CONFIRMED : 'تایید شده',
                }
                return e => a[e];
            },
        },
        methods: {
            sort(e) {

                let sort = this.$toSnakeCase(e.sortBy)

                let sorting = e.sortDesc ? 'DESC' : 'ASC'

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        orderBy: sort,
                        sorting: sorting
                    }
                })

                this.getData(1, this.perPage)
            },

            async getData(page, perPage) {
                this.state.loading = true

                const queryParams = {
                    size: perPage,
                    page: page,
                    ...this.$route.query
                }
                const address = '/wallets/withdrawal-requests'
                const res = await this.$axios(
                    address,
                    {
                        params: {
                            withdrawalRequestStatus : 'CANCELLED_BY_ADMIN,ACCEPTED',
                            ...queryParams
                        }

                    }
                )

                this.state.loading = false
                this.items = res.data.content
                this.currentPage = res.data.number + 1
                this.rows = res.data.totalElements
            },
        },
        created() {
            this.getData()
        },
    }
</script>
<style lang="scss">
    [v-cloak] {
        opacity: 0;
    }

    .nowrap {
        white-space: nowrap;
    }

    [dir="rtl"] .test-badge {
        font-size: 10px;
        top: -6px;
        left: -5px !important;
        min-width: 16px;
        min-height: 16px;
    }
</style>
